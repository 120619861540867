import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'gatsby';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import colors from '../../themes/main-colors';
import SlugLink from '../slug-link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.875, 0, 0.625, 0),
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      '& .MuiTypography-colorTextSecondary': {
        backgroundColor: 'white',
      },
      '& .MuiBreadcrumbs-ol li': {
        maxWidth: '20ch',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: colors.darkGray2,
        fontSize: theme.spacing(1.75),
        lineHeight: theme.typography.pxToRem(18),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(1.5),
          lineHeight: theme.typography.pxToRem(16),
        },
      },
      '& .MuiBreadcrumbs-ol li a': {
        textDecoration: 'none',
        color: colors.sparkBlue,
      },
      '& .MuiBreadcrumbs-ol li:last-child': {
        color: colors.noirBlur,
      },
      '& > nav': {
        height: 30,
        [theme.breakpoints.down('sm')]: {
          height: '100%',
        },
      },
    },
  }),
);

type breadcrumbType = {
  to: string;
  label: string;
};

interface breadcrumbProps {
  path: breadcrumbType[];
}

export default (props: breadcrumbProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Breadcrumbs
        maxItems={3}
        itemsBeforeCollapse={2}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {props.path &&
          props.path.map(({ to, label }, index) => (
            <SlugLink slug={to} linkText={label} key={index} />
          ))}
      </Breadcrumbs>
    </div>
  );
};
